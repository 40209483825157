import React, {Component} from 'react';
import Navbar from '../NavBar';
import Footer from '../Footer';
import UserAccountInfo from './userAccountInfo';
import BillingInfo from './BillingInfo';
import config from '../../constants/config'
//import ResetPassword from './resetPassword';


export default class UserSettigns extends Component {
    constructor(props) {
        super(props);
        
        let user = localStorage.getItem('user');
        //let userId = user ? JSON.parse(user).id : null;
        
        this.state = {
            resetPasswordClicked: false,
            //userId,
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleResetPasswordClick = this.handleResetPasswordClick.bind(this);
    }

    handleClick(event) {
        console.log('Reset false');
        this.setState({
            resetPasswordClicked: false
        });
    }
    
    handleResetPasswordClick(event) {
        let confirmation = window.confirm("Are you sure you want to reset your password?");
        console.log('Reset', confirmation);
        this.setState({
            resetPasswordClicked: confirmation
        });
    }

    render() {

        return (
            <React.Fragment>
            <div class="mb-7">
                <section class="topbar bg-dark text-white d-block">
                    <div class="container small-3">
                        <nav class="nav">
                          <a class="nav-link" href="/index"><i class="fa fa-angle-double-left mr-2" aria-hidden="true"></i>Back</a>
                        </nav>

                        <div>
                            <a>Welcome to Adminland</a>
                        </div>
                    </div>
                </section>
            </div>
            <Navbar />
                <main class="main-content bg-gray">
                    <div class="section pt-4 pt-md-6">

                        <div class="container">
                            <div class="row ml-md-8">


                            {/*<!-- Sidebar -->*/}

                            <div class="col-12 col-md-3 mb-7">

                                <ul class="nav nav-sidebar nav-sidebar-hero flex-column" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link ml-1" data-toggle="tab" href="#profile"><strong><i class="fa fa-id-badge mr-2"></i>User Profile</strong></a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link ml-1" data-toggle="tab" href="#billing"><strong><i class="fa fa-credit-card mr-2" aria-hidden="true"></i>Billing Information</strong></a>
                                    </li>
                                    <li class="nav-item" style={{display: "none"}}>
                                        <a class="nav-link ml-1" data-toggle="tab" href="#notif-settings"><strong><i class="fa fa-bell mr-2" aria-hidden="true"></i>Notification Settings</strong></a>
                                    </li>
                                </ul>
                            </div>

                            <div class="col-12 col-md-8">
                                <div class="tab-content">
                                    <div class="tab-pane fade show active" id="profile">
                                        <UserAccountInfo
                                            eiBackend={config.eiBackend}
                                            adBackend={config.apiUrl}
                                        />
                                    </div>

                                    <div class="tab-pane fade" id="billing">
                                        <BillingInfo stripeKey={config.stripe_key} />
                                    </div>

                                    <div class="tab-pane fade" id="notif-settings" style={{display: "none"}}>
                                        <h2 class="text-center">Notification Settings</h2>

                                        <div class="card border">
                                            <div class="card-body bg-gray">
                                                <div class="row gap-y mb-6">
                                                    <p class="text-center mb-5"></p>
                                                </div>

                                            </div>
                                        </div> 
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </main>
    <Footer />
    </React.Fragment>

        )
    }
}


/*export default class UserSettigns extends Component {
    constructor(props) {
        super(props);

        // let user = localStorage.getItem('user');
        // let userId = user ? JSON.parse(user).id : null;
        const userId = ApiService.getJWTUsername()
        const userInfo = ApiService.getJWTInfo()

        this.state = {
            // resetPasswordClicked: false,
            userInfo,
            showUserInfo: true
        };

        //this.handleClick = this.handleClick.bind(this);
        //this.handleResetPasswordClick = this.handleResetPasswordClick.bind(this);
    }

    // handleClick(event) {
    //     console.log('Reset false');
    //     this.setState({
    //         resetPasswordClicked: false
    //     });
    // }

    // handleResetPasswordClick(event) {
    //     let confirmation = window.confirm("Are you sure you want to reset your password?");
    //     console.log('Reset', confirmation);
    //     this.setState({
    //         resetPasswordClicked: confirmation
    //     });
    // }

    userInfoSelected = () => {
        this.setState({
            ...this.state,
            showUserInfo: true
        })
    }

    billingInfoSelected = () => {
        this.setState({
            ...this.state,
            showUserInfo: false
        })
    }

    render() {
        return (
            <div>
                <Navbar username={ this.state.userInfo["cognito:username"] }/>
                <main className="main-content bg-gray">
                    <div className="section">
                        <div className="container">
                            <div className="row">


                
                                <div className="col-3">
                                    <br /><br />

                                    <ul className="nav nav-sidebar nav-sidebar-hero flex-column" role="tablist" data-accordion="true">
     
                                        <li className="nav-item">
                                            <a className="nav-link ml-1" data-toggle="tab" href="#" onClick={this.userInfoSelected.bind(this)}>
                                                <strong><i className="fa fa-gear mr-2"></i>User Account</strong>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link ml-1" data-toggle="tab" href="#" onClick={this.billingInfoSelected.bind(this)}>
                                                <strong><i className="fa fa-credit-card mr-2" aria-hidden="true"></i>Billing Info</strong>
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div className="col-9">
                                    <div className="tab-content">
                                        {this.state.showUserInfo ?
                                            <UserAccountInfo userInfo={ this.state.userInfo } /> :
                                            <StripeProvider apiKey={ config.stripe_key }>
                                                <Elements>
                                                    <BillingInfo userInfo={ this.state.userInfo } />
                                                </Elements>
                                            </StripeProvider>
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}*/
