import React, {Component} from 'react';
import jwtDecode from 'jwt-decode';
import ajax from 'superagent';
import { Auth } from 'aws-amplify';
import SuccessPopup from '../Popups';


export default class UserAccountInfo extends Component {

    constructor(props){      
        super(props);

        const userJWT = localStorage.getItem('token');
        const userDecoded = jwtDecode(userJWT);
        let phone = userDecoded['custom:phone'] || '';
        let companyname = userDecoded['custom:company'] || '';
        let phoneIsPrivate = userDecoded['custom:private'] || false;

        this.state={
            userInfo: userDecoded,
            username: userDecoded['cognito:username'],
            phone,
            companyname,
            phoneIsPrivate,
            userJWT
        }
    }

    componentWillMount = () => {
        let {companyname, phone, username, userJWT} = this.state;
        let {eiBackend} = this.props;

        ajax.get(`${eiBackend}/users/mycontactinfo/${userJWT}`)
          .end((error, response) => {
            if (!error && response) {
                console.log("user info",response.body.info)
                let {phonenumber, companyname, phonenumber_isprivate} = response.body.info;
                this.setState({phone: phonenumber, companyname, phoneIsPrivate: phonenumber_isprivate});
            } else {
                console.log('There was an error fetching', error);
            }
          }
        );   
        
    }

    togglePrivate = async (e) => {
        let {eiBackend} = this.props;

        e.preventDefault();
        console.log("Calling togglePrivate")

        let {userInfo, phoneIsPrivate} = this.state;

        let isPrivate = (phoneIsPrivate ? '0' : '1');

        console.log("togglePrivate isPrivate", isPrivate);
        let currentCognitoUser = await Auth.currentUserPoolUser();
        console.log("currentCognitoUser", currentCognitoUser);

        let result = await Auth.updateUserAttributes(
            currentCognitoUser,
            {
                'custom:private': isPrivate
            }
        )

        console.log("togglePrivate AWS update result: ", result);

        if (result === "SUCCESS"){

            ajax.post(`${eiBackend}/users/update/contactinfo`)
            .send({
                username: userInfo['cognito:username'], 
                isPrivate
            })
            .end((error, response) => {
                if (!error && response) {
                    this.setState({showPopup: true, phoneIsPrivate: !phoneIsPrivate});
                } else {
                console.log('There was an error fetching', error);
                }
            });
        }
     
    }

    render = () => {

        let {userInfo, showPopup} = this.state; 

        return(<div>
            <div className="section">
                <div>
                    <h3>User Account Info</h3>
                </div>
                <div>
                    Name: { userInfo.name }
                </div>
                <div>
                    Email: { userInfo.email }
                </div>
                <div>
                    Username: { userInfo["cognito:username"] }
                </div>
                <div>
                    Mobile Phone Number: { this.state.phone }
                </div>
                <div>
                    Company Website: { this.state.companyname }
                </div>

                <p class="small">Your information will only be shared with users who are connected to you. You can change your privacy settings at any time.</p>

                <div className="switch" onClick={async (e) => await this.togglePrivate(e)}>
                    <input type="checkbox" 
                        name="optingIn" 
                        id="optingIn" 
                        className="switch-input" 
                        checked={this.state.phoneIsPrivate}
                      
                    />
                    <label className="switch-label">Keep my contact info private</label>
                </div>


            </div>

            <SuccessPopup
                popupId="popupPrivacyUpdated"
                title="Success!"
                message="Privacy Info Info Updated"
                trigger={showPopup}
                callback={() => {
                  this.setState({showPopup: false})
                }}
              />
        </div>);
    }

}

